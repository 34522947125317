import React from 'react';
import './App.css';

class BridgeMe extends React.Component {
  state = { name1: '', name2: '', 
            sidebar: 'No Talent Selected Yet', details: null,
            items: null, 
            bridge: '', bridgeLabel: '',
            films1: '', films2: '',
            error: null, errorMsg: null,
            nameList:[], nameList2:[], 
            origin: '', target: '',
            originID: '', targetID: '',
            targetDetails: '', originDetails: ''
          };
  echoText = document.getElementById("echoBox");
  item = '';
  listItems: JSX.Element[] = []; 
  inputFormat: string = `bg-white 
                         appearance-none 
                         border 
                         border-purple-500 
                         rounded 
                         w-full 
                         p-2 
                         text-gray-800 
                         leading-tight 
                         focus:outline-none 
                         focus:bg-gray-200`;
  
  errorThis(myError: any){
    var errorText: JSX.Element = <span></span>
    if (myError !== null) {
      errorText = <div className="flex bg-orange-200 p-4 my-2">
      <div className="mr-4">
        <div className="h-10 w-10 text-white bg-orange-600 rounded-full flex justify-center items-center">
          <b>!</b>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="text-orange-600">
          <p className="mb-2 font-bold">
            Error:
          </p>
          <p className="text-xs">{myError.toString()}</p>
        </div>
      </div>
      </div>
      this.setState({errorMsg: errorText});
    } else {
      this.setState({errorMsg: null});
    }
  }

  pullBridge(start:string, end:string){
    this.setState({bridgeLabel: "Loading...", films1: "", films2: ""}) ;
    fetch('https://www.lathrios.com/testbed/connections.php?n0='+start+'&n1='+end)
      .then(result => result.json())
      .then(
        (result) => {
          this.setState({bridge: result})
        },
        (error) => {
            this.errorThis(error)
        })
      .then(
        (resultJson) => this.publishBridge(this.state.bridge, 0)
      ) 
  }

  publishBridge(bridgeList: any, indexMe: number){

    var bridgeInfo: JSX.Element;
    var previousLink: JSX.Element; 
    var nextLink: JSX.Element;
    var filmArray: string[];
    var filmList: string;
    
    var arrayLen: number = bridgeList.length;

    if (arrayLen>0) {

      if (indexMe > bridgeList.length - 1) {
        indexMe = bridgeList.length - 1
      } 
      if (indexMe === bridgeList.length - 1) {
        nextLink = <span  className="text-gray-600">next</span>
      } else {
        nextLink = <span className="text-red-600" onClick={e => this.publishBridge(this.state.bridge, indexMe + 1)}>next</span>
      }
      if (indexMe === 0) {
        previousLink = <span className="text-gray-600">previous</span>
      } else {
        previousLink = <span  className="text-red-600" onClick={e => this.publishBridge(this.state.bridge, indexMe - 1)}>previous</span>
      }
      
      var i: number;
      filmArray = [];
      filmList = '';
      for (i = 0; i < bridgeList[indexMe]['contentA'].length; i++) { 
        filmArray.push(bridgeList[indexMe]['contentA'][i]["title"] + " (" + bridgeList[indexMe]['contentA'][i]["year"]+ ")")
      }
      filmArray = filmArray.filter((x, i, a) => a.indexOf(x) === i)
      filmList += filmArray.join(", ")
      this.setState({films1: filmList});

      filmArray = [];
      filmList = '';
      for (i = 0; i < bridgeList[indexMe]['contentB'].length; i++) { 
        filmArray.push(bridgeList[indexMe]['contentB'][i]["title"] + " (" + bridgeList[indexMe]['contentB'][i]["year"]+ ")")
      }
      filmArray = filmArray.filter((x, i, a) => a.indexOf(x) === i)
      filmList += filmArray.join(", ")
      

      if(bridgeList[indexMe]['bridge'] === '') {
        this.setState({films2: ''});
        bridgeInfo = <div><span className='text-xl text-gray-800'>Direct Connection</span> <br/>
          Connectivity: {bridgeList[indexMe]['prob']}%<br/>
      &lt; {previousLink} ::  {indexMe + 1} of {arrayLen} :: {nextLink} &gt; 
        </div>;
      } else {
        var dod: JSX.Element = <span></span>
        
        if (bridgeList[indexMe]['bridgeDOD'] !== null) {
          dod = <span className="text-red-700 font-bold"> Deceased<br/></span>;
        } 
        
        this.setState({films2: filmList});
        bridgeInfo = <div>
        <span className='text-xl font-bold text-gray-800'>{bridgeList[indexMe]['bridge']} </span><br/>{dod}
        <button className='text-sm text-blue-500' onClick={() => this.setSidebar(bridgeList[indexMe]['bridgeID'])}>Show Details</button>
        <br/>
        Connectivity: {bridgeList[indexMe]['prob']}%<br/>
&lt; {previousLink} ::  {indexMe + 1} of {arrayLen} :: {nextLink} &gt; 
        </div>;
      }
      
      this.setState({bridgeLabel: bridgeInfo});
    } else {
      this.setState({bridgeLabel: 'No Contacts Found'}) ;
    }
  }

  setSidebar(talentID: any) {
    this.setState({sidebar: "Loading...", errorMsg: null}) 
    fetch('https://www.lathrios.com/testbed/getdetail.php?q='+talentID)
      .then(result => result.json())
      .then(
        (result) => {
          this.setState({details: result})
        },
        (error) => {
            this.errorThis(error)
        })
      .then(
        (resultJson) => this.publishSidebar(this.state.details)
      ) 
  }

  publishSidebar(content: any) {

    
    const contentList = content['jobs'].map((item: any) =>
      <span className='text-sm text-gray-700'><b>{item['title']}</b> ({item['releaseDate'].substring(0, 4)})&nbsp;
      {item['department']} - {item['job']}<br/></span>
    );
    
        
    const assocList = content['assoc'].map((item: any) =>
      <span><button className='text-sm text-gray-700 font-bold' onClick={() => this.setSidebar(item['talentID'])}>{item['fullname']}</button>&nbsp;
                      <button className='text-sm text-blue-500' onClick={() => this.selectName(item['talentID'], item['fullname'], 1)}>Origin</button> |&nbsp; 
                      <button className='text-sm text-blue-500' onClick={() => this.selectName(item['talentID'], item['fullname'], 2)}>Target</button>
                      <br/></span>
    );
    const rankingList = content['ranking'].map((item: any) =>
      <span className='text-sm text-gray-700'>#<b>{item['rank']}</b> {item['department']}<br/></span>
    );
    const url: string = "https://www.themoviedb.org/person/" + content['theMovieDB'];
    var outsideLink: JSX.Element = <span><a href={url} target='new' className='text-sm text-blue-500'>TheMovieDB</a></span>
    if ( content['imdb'] !== '') {
      const url2 = "https://www.imdb.com/name/" + content['imdb'];
      outsideLink = <span className='text-sm text-gray-500'>
                    <a href={url2} target='new' className='text-blue-500'>IMDb</a> &bull; 
                    <a href={url} target='new' className='text-sm text-blue-500'>TheMovieDB</a></span>
    } 
    var dod: JSX.Element = <span><br/>&nbsp;</span>
    if (content['DOD'] !== null) {
      dod = <span className="text-red-700 font-bold"><br/>Deceased<br/>&nbsp;</span>;
    } 

    const side: JSX.Element =
            <div>
              <h3 className='text-2xl font-bold'>{content['label']}</h3>
              {outsideLink}{dod}
              <p className='text-red-700 text-xl font-bold'>RANKING</p>
              <p>{rankingList}</p>
              &nbsp;
              <p className='text-red-700 text-xl font-bold'>TOP ASSOCIATIONS</p>
              <p>{assocList}</p>
              &nbsp;
              <p className='text-red-700 text-xl font-bold'>FILMOGRAPHY</p>
              <p>{contentList}</p>
              &nbsp;
            </div>

    this.setState({sidebar: side}) ;
    //this.setState({sidebar: JSON.stringify(content)}) ;
  }
  
  selectName2(e: any){  
    const nameArray = e.currentTarget.dataset.id.split("|");
    var startMe = "";
    var endMe = "";
    var detailsButton: JSX.Element;

    detailsButton = <button className='text-sm text-blue-500' onClick={() => this.setSidebar(nameArray[0])}>Show Detials</button>

    if (nameArray[2] === "1") {
      this.setState({origin: nameArray[1], originID: nameArray[0], originDetails: detailsButton });
      startMe = nameArray[0];
      endMe = this.state.targetID;
    } else {
      this.setState({target: nameArray[1], targetID: nameArray[0], targetDetails: detailsButton });
      startMe = this.state.originID;
      endMe = nameArray[0];
    }
    this.setState({name1: '', name2: '', nameList:[], nameList2:[]  });
    
    if(startMe === endMe) {
      this.errorThis("Origin and Target Names are the same.");
    } else if ( startMe !== '' && endMe !=='') {
      this.pullBridge(startMe, endMe);  // make sure right type here
    }
    return('done');
  }

  selectName(tid: number, fullname: string, element: number){  
    var startMe: string = "";
    var endMe: string = "";
    var detailsButton: JSX.Element;
    this.setState({errorMsg: null});

    detailsButton = <button className='text-sm text-blue-500' onClick={() => this.setSidebar(tid)}>Show Details</button>

    if (element === 1) {
      this.setState({origin: fullname, originID: tid, originDetails: detailsButton });
      startMe = tid.toString();
      endMe = this.state.targetID;
    } else {
      this.setState({target: fullname, targetID: tid, targetDetails: detailsButton });
      startMe = this.state.originID;
      endMe = tid.toString();
    }
    this.setState({name1: '', name2: '', nameList:[], nameList2:[]  });

    if(startMe === endMe) {
      this.errorThis("Origin and Target Names are the same.");
    } else if ( startMe !== '' && endMe !=='') {
      this.pullBridge(startMe, endMe);  // make sure right type here
    }
    return('done');
  }

  listNames(jsonItems: any, elem: number){
    
    if (elem === 1) {
      this.listItems = [];
      for (this.item in jsonItems) {
        const thisName = jsonItems[this.item]['tid'] + '|' + jsonItems[this.item]['label'] + "|" + elem.toString() + "|" + jsonItems[this.item]['imdb']+ "|" + jsonItems[this.item]['theMovieDB'];
        this.listItems.push(<li className='text-gray-600 hover:text-blue-700 hover:bg-blue-100 hover:font-bold' 
                                onClick={this.selectName2.bind(this)} 
                                key={jsonItems[this.item]['tid']} 
                                data-id={thisName}>{jsonItems[this.item]['label']} </li>);
      }
      this.setState({nameList: this.listItems})
    } else {
      this.listItems = [];
      for (this.item in jsonItems) {
        const thisName = jsonItems[this.item]['tid'] + '|' + jsonItems[this.item]['label'] + "|" + elem.toString() + "|" + jsonItems[this.item]['imdb']+ "|" + jsonItems[this.item]['theMovieDB'];
        this.listItems.push(<li className='text-gray-600 
                                hover:text-blue-700 
                                hover:bg-blue-100 
                                hover:font-bold' 
                                onClick={this.selectName2.bind(this)} 
                                key={jsonItems[this.item]['tid']} 
                                data-id={thisName}>{jsonItems[this.item]['label']} </li>);
      }
      this.setState({nameList2: this.listItems})
    }
    
    return('done');
  }

  getNames(textMe: string, elem: number){
    this.errorThis(null);
    if(elem === 1) {
      this.setState({name1: textMe, items: [], error: null });
    } else {
      this.setState({name2: textMe, items: [], error: null });
    }
    
    fetch("https://www.lathrios.com/testbed/getname.php?q=" + textMe)
      .then(result => result.json())
      .then(
        (result) => {
          this.setState({items: result})
        },
        (error) => {
            this.errorThis(error)
        })
      .then(
        (resultJson) => this.listNames(this.state.items, elem)
      ) 
  }
 
  render() {
    return (
      <div>
        {this.state.errorMsg}
        <div className='flex pb-2'>
            <div id='findname' className="md:w-2/6 ">
                <label>New Origin
                <input 
                  className={this.inputFormat}
                  id="name1"
                  type="text"
                  value={this.state.name1}
                  onChange={e => this.getNames(e.target.value, 1)}
                /> </label>
                <ul className="p-2">{this.state.nameList}</ul>
            </div>
            <div className="md:w-1/6 text-center">
            &nbsp;<p>&nbsp;&bull;&bull;&bull;&nbsp;</p>
            </div>
            <div id='findname2' className='md:w-2/6'>
            <label>New Target
            <input 
                className={this.inputFormat} 
                id="name1"
                type="text"
                value={this.state.name2}
                onChange={e => this.getNames(e.target.value, 2)}
              /> </label>
              <ul className='p-2'>{this.state.nameList2} </ul>
            </div>
          </div>
          <div className='flex flex-wrap'>
            <div className='sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/3 pr-4' >
              <div className='w-auto flex'>
                <div className='w-1/3 pr-4'>
                  <div className="rounded-full h-20 w-20 flex items-center justify-center border">origin</div> </div>
                <div className='w-2/3 align-middle'><h3 className='text-xl font-bold text-gray-800'>{this.state.origin} </h3> 
                {this.state.originDetails}</div>
              </div>
              <div className='w-full flex'>
                <div className='w-1/3 pr-4'>
                  <div className="h-20 w-20 flex items-center justify-center">
                    <img alt='down arrow' className="w-20 opacity-25" src="/arrow-thick-down.svg"/>
                  </div>
                </div>
                <div className='w-2/3'>{this.state.films1}<br/>&nbsp;</div>
              </div>
              <div className='w-full flex'>
                <div className='w-1/3 pr-4'>
                  <div className="rounded-full h-20 w-20 flex items-center justify-center border">bridge</div></div>
                  <div className='w-2/3'>{this.state.bridgeLabel}</div>
              </div>
              <div className='w-full flex'> 
              <div className='w-1/3 pr-4'>
                  <div className="h-20 w-20 flex items-center justify-center">
                    <img alt='down arrow' className="w-20 opacity-25" src="/arrow-thick-down.svg"/>
                  </div>
                </div>
                <div className='w-2/3'>&nbsp;<p>{this.state.films2}</p>&nbsp;</div>
              </div>
              <div className='w-full flex'>
                <div className='w-1/3 pr-4'>
                  <div className="rounded-full h-20 w-20 flex items-center justify-center border">target</div><br/>&nbsp;</div>
                <div className='w-2/3 align-middle'><h3 className='text-xl font-bold text-gray-800'>{this.state.target}</h3>
                {this.state.targetDetails} </div>
              </div>
            </div>
            <div className='sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3'>
                <ul className="flex">
                  <li className="mr-3">
                    <span className="inline-block border border-blue-500 rounded py-1 px-3 bg-blue-500 text-white">Detail</span>
                  </li>
                  <li className="mr-3">
                    <span className="inline-block py-1 px-3 text-gray-400">Film</span>
                  </li>
                  <li className="mr-3">
                    <span className="inline-block py-1 px-3 text-gray-400">Ranking</span>
                  </li>
                  <li className="mr-3">
                    <span className="inline-block py-1 px-3 text-gray-400">Chart</span>
                  </li>
                </ul>&nbsp;
                <p>{this.state.sidebar}</p>
            </div>
          </div>
        </div>
        
    )
  }
}

function App() {
  return (
        <BridgeMe />
  );
}

export default App;

